import React from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"

import { Layout } from "src/components/Layout"
import { Helmet } from "src/components/Helmet"
import { Container } from "src/components/Container"

const SectionTemplate = ({
  data: {
    section: { title, category },
  },
}) => {
  return (
    <>
      <Helmet title={title}></Helmet>
      <Layout>
        <Container>
          <Heading>{title}</Heading>
          <Categories>
            {category.map(({ name, slug, id }) => (
              <Category key={id}>
                <Link to={`/category/${slug}`}>{name}</Link>
              </Category>
            ))}
          </Categories>
        </Container>
      </Layout>
    </>
  )
}

export default SectionTemplate

export const query = graphql`
  query($id: String!) {
    section: datoCmsSection(id: { eq: $id }) {
      title
      category {
        name
        slug
        id
      }
    }
  }
`

const Heading = styled.h1`
  background-color: #fff;
  border-radius: 6px;
  padding: 16px;
  font-weight: bold;
  font-size: 32px;

  color: ${({ theme }) => theme.colors.contrast};
`

const Categories = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  margin-bottom: 40px;
`

const Category = styled.div`
  background-color: #fff;
  border-radius: 6px;
  flex-basis: 49%;
  margin-bottom: 24px;

  a {
    transition: all 0.2s;
    font-size: 18px;
    display: block;
    padding: 16px;

    &:first-letter {
      text-transform: uppercase;
    }

    &:hover {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.l}) {
    flex-basis: 100%;
  }
`
